import { css } from '@emotion/react'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat'
import {
  useConversations,
  editConversationInput,
  updateBotResponseStatus
} from '../../redux/features/conversations/conversationsSlice'
import { ChatHistory } from './chat-history/ChatHistory'
import { ChatInput } from './ChatInput'
import { ChatPrompts } from './ChatPrompts'

const classes = {
  root: css({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }),
  scroll: css({
    margin: '4px',
    height: '100%',
    overflowY: 'auto',
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        visibility: 'visible'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#ffffff',
        WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
        visibility: 'visible'
      }
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }),
  history: css({
    padding: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    display: 'flex',
    justifyContent: 'center'
  }),
  input: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '8px 0px'
  }),
  wrapper: css({
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15px',
    display: 'flex'
  })
}

export const ChatRoom: React.FC = () => {
  const chat = useChat()
  const dispatch = useDispatch()
  const { conversations, selectedId } = useConversations()

  const messages = useMemo(() => {
    return conversations[selectedId]?.messages || []
  }, [conversations, selectedId])

  const scrollViewTargetRef = useRef<HTMLDivElement>(null)
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

  useEffect(() => {
    if (!shouldAutoScroll) {
      return
    }
    scrollViewTargetRef.current?.scrollTo(
      0,
      scrollViewTargetRef.current.scrollHeight
    )
  }, [messages, shouldAutoScroll])

  useEffect(() => {
    const onScroll = () => {
      if (!scrollViewTargetRef.current) {
        return
      }
      const { scrollTop, scrollHeight, clientHeight } =
        scrollViewTargetRef.current
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10
      setShouldAutoScroll(isAtBottom)
    }
    if (!scrollViewTargetRef.current) {
      return
    }
    const currentScrollViewTarget = scrollViewTargetRef.current
    currentScrollViewTarget.addEventListener('scroll', onScroll)
    return () => {
      currentScrollViewTarget.removeEventListener('scroll', onScroll)
    }
  }, [])

  const handleSubmit = async (options: GetResponseOptions) => {
    dispatch(editConversationInput({ id: selectedId, newInput: '' }))
    dispatch(
      updateBotResponseStatus({
        chatId: selectedId,
        status: 'processing your request'
      })
    )
    options.advancedReasoning = conversations[selectedId].advancedReasoning
    await chat.getResponse(options)
    setShouldAutoScroll(true)
  }

  if (conversations[selectedId]?.hidden || false) {
    return (
      <div css={classes.root}>
        <div css={classes.scroll}>
          <div css={classes.history}>
            <h3>This conversation is not visible in the app.</h3>
          </div>
        </div>
      </div>
    )
  }

  if (conversations[selectedId] === undefined) {
    return (
      <div css={classes.root}>
        <div css={classes.scroll}>
          <div css={classes.history} />
        </div>
      </div>
    )
  }

  return (
    <div css={classes.root}>
      <div ref={scrollViewTargetRef} css={classes.scroll}>
        <div css={classes.history}>
          <ChatHistory messages={messages} />
        </div>
        <ChatPrompts
          onSubmit={handleSubmit}
          conversation={conversations[selectedId]}
          selectedId={selectedId}
        />
      </div>
      <div css={classes.input}>
        <ChatInput onSubmit={handleSubmit} />
      </div>
    </div>
  )
}
