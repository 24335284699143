import { useDispatch } from 'react-redux'
import botIcon1 from '../../assets/bot-icons/bot-icon-1.png'
import botIcon2 from '../../assets/bot-icons/bot-icon-2.png'
import botIcon3 from '../../assets/bot-icons/bot-icon-3.png'
import botIcon4 from '../../assets/bot-icons/bot-icon-4.png'
import botIcon5 from '../../assets/bot-icons/bot-icon-5.png'
import { getErrorDetails } from '../../components/utils/TextUtils'
import { Constants } from '../../Constants'
import { addAlert, useApp } from '../../redux/features/app/appSlice'
import { ChatState } from '../../redux/features/conversations/ChatState'
import {
  addConversation,
  addMessageToConversationFromUser,
  deleteConversation,
  setConversations,
  setSelectedConversation,
  updateBotResponseStatus,
  updateUserIsTyping,
  useConversations,
  updateFinalMessageFlag,
  updateFinalActionFlag,
  updateSuggestedQuestions
} from '../../redux/features/conversations/conversationsSlice'
import { Conversations } from '../../redux/features/conversations/ConversationsState'
import Connector from '../../redux/features/message-relay/signalRConnection'
import { AlertType } from '../models/AlertType'
import {
  AuthorRoles,
  ChatMessageType,
  IChatMessage
} from '../models/ChatMessage'
import {
  ICreateChatSessionResponse,
  RouteLocation,
  mapLocation
} from '../models/ChatSession'
import { IChatUser } from '../models/ChatUser'
import { IAskVariables } from '../semantic-kernel/model/Ask'
import { ChatService } from '../services/ChatService'
import { useAuth } from './useAuth'

export interface GetResponseOptions {
  messageType: ChatMessageType
  value: string
  advancedReasoning: boolean
  chatId: string
  kernelArguments?: IAskVariables[]
  processPlan?: boolean
}

export const useChat = () => {
  const dispatch = useDispatch()
  const { conversations } = useConversations()
  const { activeUserInfo } = useApp()
  const { apiBaseUrl, getToken } = useAuth()
  const apiRoot = apiBaseUrl
  const apiBaseUrlSignalR = `${apiBaseUrl}/rcopilot`

  const chatService = new ChatService(apiRoot)

  const botProfilePictures: string[] = [
    botIcon1,
    botIcon2,
    botIcon3,
    botIcon4,
    botIcon5
  ]

  const userId = activeUserInfo?.id ?? ''
  const fullName = activeUserInfo?.username ?? ''
  const emailAddress = activeUserInfo?.email ?? ''
  const loggedInUser: IChatUser = {
    id: userId,
    fullName,
    emailAddress,
    photo: undefined, // TODO: [Issue #45] Make call to Graph /me endpoint to load photo
    online: true,
    isTyping: false
  }

  const getChatUserById = (id: string, chatId: string, users: IChatUser[]) => {
    if (id === `${chatId}-bot` || id.toLocaleLowerCase() === 'bot') {
      return Constants.bot.profile
    }
    return users.find((user) => user.id === id)
  }

  const createChat = async (routeLocation: string, householdId: string) => {
    const token = await getToken()
    const chatTitle = `Copilot @ ${new Date().toLocaleString()}`
    const location: RouteLocation = mapLocation(routeLocation)
    try {
      await chatService
        .createChatAsync(chatTitle, location, householdId, token)
        .then((result: ICreateChatSessionResponse) => {
          const firstMessage: IChatMessage = {
            chatId: result.chatSession.id,
            timestamp: result.initialBotMessage.timestamp,
            userId: result.initialBotMessage.userId,
            userName: 'RCopilot',
            content: result.initialBotMessage.content,
            type: ChatMessageType.Message,
            authorRole: AuthorRoles.Bot
          }
          const newChat: ChatState = {
            id: result.chatSession.id,
            title: result.chatSession.title,
            systemDescription: result.chatSession.systemDescription,
            memoryBalance: result.chatSession.memoryBalance,
            messages: [firstMessage],
            users: [loggedInUser],
            botProfilePicture: getBotProfilePicture(
              Object.keys(conversations).length
            ),
            input: '',
            botResponseStatus: undefined,
            userDataLoaded: false,
            disabled: false,
            hidden: false,
            location: result.chatSession.location,
            householdId: result.chatSession.householdId,
            suggestedQuestions: result.chatSession.suggestedQuestions,
            advancedReasoning: false,
            finalMessageFlag: true,
            finalActionFlag: true
          }
          dispatch(addConversation(newChat))
          const { addClientToGroupAsync } = Connector(apiBaseUrlSignalR, token)
          addClientToGroupAsync(result.chatSession.id)
          return newChat.id
        })
    } catch (e: any) {
      const errorMessage = `Unable to create new chat. Details: ${getErrorDetails(
        e
      )}`
      dispatch(addAlert({ message: errorMessage, type: AlertType.Error }))
    }
  }

  const getResponse = async ({
    messageType,
    value,
    advancedReasoning,
    chatId,
    kernelArguments
  }: GetResponseOptions) => {
    dispatch(updateFinalMessageFlag({ chatId: chatId, flag: false }))
    dispatch(updateFinalActionFlag({ chatId: chatId, flag: false }))
    dispatch(
      updateSuggestedQuestions({
        chatId: String(chatId),
        suggestedQuestions: []
      })
    )
    const token = await getToken()
    const chatInput: IChatMessage = {
      chatId: chatId,
      timestamp: new Date().getTime(),
      userId: activeUserInfo?.id as string,
      userName: activeUserInfo?.username as string,
      content: value,
      type: messageType,
      authorRole: AuthorRoles.User
    }

    dispatch(
      addMessageToConversationFromUser({ message: chatInput, chatId: chatId })
    )
    const { sendMessageAsync } = Connector(apiBaseUrlSignalR, token)
    sendMessageAsync(chatId, activeUserInfo?.id as string, chatInput)

    const ask = {
      input: value,
      advancedReasoning: advancedReasoning,
      variables: [
        {
          key: 'chatId',
          value: chatId
        },
        {
          key: 'messageType',
          value: messageType.toString()
        }
      ]
    }

    if (kernelArguments) {
      ask.variables.push(...kernelArguments)
    }

    try {
      await chatService
        .getBotResponseAsync(ask, token)
        .then((data) => {
          dispatch(
            updateSuggestedQuestions({
              chatId: String(chatId),
              suggestedQuestions: data.suggestedQuestions
            })
          )
        })
        .catch((e: any) => {
          throw e
        })
    } catch (e: any) {
      dispatch(updateFinalMessageFlag({ chatId: chatId, flag: true }))
      dispatch(updateFinalActionFlag({ chatId: chatId, flag: true }))
      dispatch(updateBotResponseStatus({ chatId, status: undefined }))

      const errorMessage = `Unable to generate bot response. Details: ${getErrorDetails(
        e
      )}`
      dispatch(addAlert({ message: errorMessage, type: AlertType.Error }))
    }
  }

  const loadChats = async (routeLocation: string, householdId: string) => {
    try {
      const token = await getToken()
      const chatSessions = await chatService.getAllChatsAsync(token)

      if (chatSessions.length > 0) {
        const loadedConversations: Conversations = {}
        for (const chatSession of chatSessions) {
          const chatUsers = await chatService.getAllChatParticipantsAsync(
            chatSession.id,
            token
          )
          const chatMessages = await chatService.getChatMessagesAsync(
            chatSession.id,
            0,
            100,
            token
          )

          loadedConversations[chatSession.id] = {
            id: chatSession.id,
            title: chatSession.title,
            systemDescription: chatSession.systemDescription,
            memoryBalance: chatSession.memoryBalance,
            users: chatUsers,
            messages: chatMessages,
            botProfilePicture: getBotProfilePicture(
              Object.keys(loadedConversations).length
            ),
            input: '',
            botResponseStatus: undefined,
            userDataLoaded: false,
            disabled: false,
            hidden: chatUsers.length > 1,
            location: chatSession.location,
            householdId: chatSession.householdId,
            suggestedQuestions: chatSession.suggestedQuestions,
            advancedReasoning: false,
            finalMessageFlag: true,
            finalActionFlag: true
          }
          const { addClientToGroupAsync } = Connector(apiBaseUrlSignalR, token)
          addClientToGroupAsync(chatSession.id)
        }

        dispatch(setConversations(loadedConversations))
        const location = mapLocation(routeLocation)
        // If there are no non-hidden chats, create a new chat
        const nonHiddenChats = Object.values(loadedConversations).filter(
          (c) =>
            !c.hidden &&
            c.location === location &&
            c.householdId === (householdId || '')
        )
        if (nonHiddenChats.length === 0) {
          // await createChat();
        } else {
          dispatch(setSelectedConversation(nonHiddenChats[0].id))
        }
      } else {
        // No chats exist, create first chat window
        // await createChat();
      }

      return true
    } catch (e: any) {
      const errorMessage = `Unable to load chats. Details: ${getErrorDetails(
        e
      )}`
      dispatch(addAlert({ message: errorMessage, type: AlertType.Error }))
      return false
    }
  }
  //UDPATING
  const updateUserTyping = async (
    userId: string,
    chatId: string,
    isTyping: boolean
  ) => {
    const token = await getToken()
    const { sendUserTypingStateAsync } = Connector(apiBaseUrlSignalR, token)
    sendUserTypingStateAsync(chatId, userId, isTyping)
    dispatch(
      updateUserIsTyping({ userId: userId, chatId: chatId, isTyping: isTyping })
    )
  }

  const getBotProfilePicture = (index: number): string => {
    return botProfilePictures[index % botProfilePictures.length]
  }
  const editChat = async (
    chatId: string,
    title: string,
    syetemDescription: string,
    memoryBalance: number,
    location: RouteLocation,
    householdId: string,
    suggestedQuestions: string[]
  ) => {
    try {
      const token = await getToken()
      await chatService.editChatAsync(
        chatId,
        title,
        syetemDescription,
        memoryBalance,
        location,
        householdId,
        suggestedQuestions,
        token
      )
    } catch (e: any) {
      const errorMessage = `Error editing chat ${chatId}. Details: ${getErrorDetails(
        e
      )}`
      dispatch(addAlert({ message: errorMessage, type: AlertType.Error }))
    }
  }

  const deleteChat = async (
    chatId: string,
    routeLocation: string,
    householdId: string
  ) => {
    // const friendlyChatName = getFriendlyChatName(conversations[chatId])

    // Only deletes locally for now until delete endpoint is implemented
    dispatch(deleteConversation(chatId))
    if (
      Object.values(conversations).filter((c) => !c.hidden && c.id !== chatId)
        .length === 0
    ) {
      // If there are no non-hidden chats, create a new chat
      void createChat(routeLocation, householdId)
    }

    // // for eventual delete endpoint
    // await chatService
    //   .deleteChatAsync(chatId, accessToken)
    //   .then(() => {
    //     dispatch(deleteConversation(chatId))
    //     if (
    //       Object.values(conversations).filter(
    //         (c) => !c.hidden && c.id !== chatId
    //       ).length === 0
    //     ) {
    //       // If there are no non-hidden chats, create a new chat
    //       void createChat(accessToken)
    //     }
    //   })
    //   .catch((e: any) => {
    //     const errorDetails = (e as Error).message.includes(
    //       'Failed to delete resources for chat id'
    //     )
    //       ? "Some or all resources associated with this chat couldn't be deleted. Please try again."
    //       : `Details: ${(e as Error).message}`
    //     dispatch(
    //       addAlert({
    //         message: `Unable to delete chat {${friendlyChatName}}. ${errorDetails}`,
    //         type: AlertType.Error,
    //         onRetry: () => void deleteChat(chatId, accessToken)
    //       })
    //     )
    //   })
  }

  return {
    getChatUserById,
    createChat,
    loadChats,
    getResponse,
    updateUserTyping,
    editChat,
    deleteChat
  }
}

export function getFriendlyChatName(convo: ChatState): string {
  const messages = convo.messages

  // Regex to match the Copilot timestamp format that is used as the default chat name.
  // The format is: 'Copilot @ MM/DD/YYYY, hh:mm:ss AM/PM'.
  const autoGeneratedTitleRegex =
    /Copilot @ [0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}, [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2} [A,P]M/
  const firstUserMessage = messages.find(
    (message) =>
      message.authorRole !== AuthorRoles.Bot &&
      message.type === ChatMessageType.Message
  )

  // If the chat title is the default Copilot timestamp, use the first user message as the title.
  // If no user messages exist, use 'New Chat' as the title.
  const friendlyTitle = autoGeneratedTitleRegex.test(convo.title)
    ? firstUserMessage?.content ?? 'New Chat'
    : convo.title

  // Truncate the title if it is too long
  return friendlyTitle.length > 60
    ? friendlyTitle.substring(0, 60) + '...'
    : friendlyTitle
}
