import { combineReducers, Reducer } from '@reduxjs/toolkit'
import appReducer from './features/app/appSlice'
import { AppState as AppStateFeature } from './features/app/AppState'
import conversationsReducer from './features/conversations/conversationsSlice'
import { ConversationsState } from './features/conversations/ConversationsState'

export interface ICopilotState {
  app: AppStateFeature
  conversations: ConversationsState
}

// Define a root reducer that combines all the reducers
export const copilotModuleReducer: Reducer<ICopilotState> = combineReducers({
  app: appReducer,
  conversations: conversationsReducer
})
