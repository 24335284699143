import { SpeechService } from '../services/SpeechService'
import { useAuth } from './useAuth'

interface TokenResponse {
  token: string
  region: string
  isSuccess: boolean
}

export const useSpeech = () => {
  const { apiBaseUrl, getToken } = useAuth()
  const apiRoot = apiBaseUrl

  const speechService = new SpeechService(apiRoot)

  const getSpeechTokenAsync = async () => {
    const token = await getToken()
    return speechService.getSpeechTokenAsync(token)
  }

  const getSpeechRecognizerAsyncWithValidKey = async (
    response: TokenResponse
  ) => {
    return speechService.getSpeechRecognizerAsyncWithValidKey(response)
  }

  return {
    getSpeechTokenAsync,
    getSpeechRecognizerAsyncWithValidKey
  }
}
