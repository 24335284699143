import { IChatMessage } from './ChatMessage'

export enum RouteLocation {
  BookDashboard,
  ClientDashboard,
  ServiceDashboard
}

export const locationMapping: { [key: string]: RouteLocation } = {
  '/advisory/dashboard': RouteLocation.BookDashboard,
  '/advisory/client': RouteLocation.ClientDashboard,
  '/advisory/alerts': RouteLocation.ServiceDashboard
}

export const mapLocation = (url: string): RouteLocation => {
  // Remove query parameters if any
  const cleanUrl = url.split('?')[0]

  // Split the URL by '/'
  const parts = cleanUrl.split('/')

  // Join the first three parts to get the base route
  const baseRoute = `/${parts[1]}/${parts[2]}`

  // Return the mapped location or a default value
  return locationMapping[baseRoute] ?? RouteLocation.BookDashboard
}

export interface IChatSession {
  id: string
  title: string
  systemDescription: string
  memoryBalance: number
  location: RouteLocation
  householdId: string
  suggestedQuestions: string[]
}

export interface ICreateChatSessionResponse {
  chatSession: IChatSession
  initialBotMessage: IChatMessage
}
