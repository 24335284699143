import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api } from 'store/api/rdot360'
import { apiConstants } from './apis'
import { selectedAccountsApiContextKey } from './rdot360Context'
import {
  IRdot360ApiResponse,
  IActivitySummary,
  GetGainLossesIncomeSummaryResponse,
  IGroupedActivitySummary,
  ICheck,
  ICheckValue
} from './types'

type FinancialsApiTagType =
  | 'activity'
  | 'estimatedIncome'
  | 'projectedIncome'
  | 'income'
  | 'gainandlosss'

const financialsApiTags: FinancialsApiTagType[] = [
  'activity',
  'estimatedIncome',
  'projectedIncome',
  'income',
  'gainandlosss'
]

const contextJsonHeader = selectedAccountsApiContextKey

const { cacheTime } = apiConstants

const rdot360ApiEnhanced = rdot360Api.enhanceEndpoints({
  addTagTypes: financialsApiTags
})
const financialsApi = rdot360ApiEnhanced.injectEndpoints({
  endpoints: (builder) => ({
    getRealizedGainLossSummary: builder.query<
      GetGainLossesIncomeSummaryResponse | undefined,
      { financialYear: number; accounts: string[]; taxable: boolean }
    >({
      query: ({ financialYear, taxable }) => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLoss('A',${financialYear},'Y','',''${
            taxable ? `,'taxable'` : ''
          })`,
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (
        response: IRdot360ApiResponse<GetGainLossesIncomeSummaryResponse>
      ): GetGainLossesIncomeSummaryResponse | undefined => response?.value?.[0],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    }),
    getRealizedGainLossSecurity: builder.query<
      GetGainLossesIncomeSummaryResponse | undefined,
      { financialYear: number; accounts: string[]; taxable: boolean }
    >({
      query: ({ financialYear, taxable }) => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLoss('S',${financialYear},'Y','',''${
            taxable ? `,'taxable'` : ''
          })`,
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (
        response: IRdot360ApiResponse<GetGainLossesIncomeSummaryResponse>
      ): GetGainLossesIncomeSummaryResponse | undefined => response?.value?.[0],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitySummary: builder.query<IActivitySummary, string[]>({
      query: () => {
        return {
          url: 'activityapi/odata/activitysummary?AccountList=&SearchBy=LatestActivity,10&DateRange=Last,30',
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (response: IRdot360ApiResponse<IActivitySummary>) =>
        response?.value?.[0] || {},
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getDetailActivitySummary: builder.query<
      IActivitySummary,
      { contextAccounts: string[]; categories?: string[]; dateRange?: string }
    >({
      query: ({ categories, dateRange }) => {
        return {
          url: `activityapi/odata/activitysummary?AccountList=&DateRange=${dateRange}${
            categories && categories.length
              ? `&SearchBy=TranCode,${categories.join(',')}`
              : ''
          }`,
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (response: IRdot360ApiResponse<IActivitySummary>) =>
        response?.value?.[0] || {},
      providesTags: ['rdot360', 'activity'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitySummaryGroup: builder.query<
      IGroupedActivitySummary,
      { contextAccounts: string[]; range: string }
    >({
      query: ({ range }) => {
        return {
          url: `activityapi/odata/activitysummary/group?DateRange=${range}`,
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (response: IGroupedActivitySummary[]) => response?.[0],
      providesTags: ['rdot360', 'activity'],
      keepUnusedDataFor: cacheTime
    }),
    retrieveCheck: builder.query<
      ICheckValue | undefined,
      {
        AccountList: string
        dateRange?: string
        FilterBy?: string
      }
    >({
      query: ({ AccountList, dateRange, FilterBy }) => ({
        url: `mm/odata/MMSummary?AccountList=${AccountList}&DateRange=${dateRange}&SearchBy=Image,Check,CKR&FilterBy=${FilterBy}`,
        headers: {
          contextjson: contextJsonHeader,
          profilejson: contextJsonHeader
        },
        method: 'GET'
      }),
      transformResponse: (response: ICheck | undefined) => response?.value?.[0],
      keepUnusedDataFor: cacheTime
    }),
    GetRealizedGainLossPossibleYears: builder.query<
      number[] | undefined,
      { accounts: string[] }
    >({
      query: () => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLossPossibleYears`,
          headers: {
            contextjson: contextJsonHeader,
            profilejson: contextJsonHeader
          }
        }
      },
      transformResponse: (response: number[] | undefined) => response || [],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    })
  })
})
export const {
  useGetRealizedGainLossSummaryQuery,
  useGetRealizedGainLossSecurityQuery,
  useLazyGetRealizedGainLossSecurityQuery,
  useGetActivitySummaryQuery,
  useGetDetailActivitySummaryQuery,
  useLazyGetDetailActivitySummaryQuery,
  useGetActivitySummaryGroupQuery,
  useRetrieveCheckQuery,
  useLazyRetrieveCheckQuery,
  useGetRealizedGainLossPossibleYearsQuery
} = financialsApi

export const useFinancialsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<FinancialsApiTagType>[]) =>
      dispatch(financialsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
