import { css } from '@emotion/react'
import { Text } from '@fluentui/react/lib/Text'
import { getFriendlyChatName } from '../../../libs/hooks/useChat'
import { ChatMessageType } from '../../../libs/models/ChatMessage'
import { useConversations } from '../../../redux/features/conversations/conversationsSlice'
import { Conversations } from '../../../redux/features/conversations/ConversationsState'
import { ChatListItem } from './ChatListItem'

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    paddingBottom: '4px'
  }),
  header: css({
    marginTop: 0,
    paddingBottom: '4px',
    marginLeft: '20px',
    marginRight: '20px',
    fontWeight: '12px', // fontWeightRegular
    fontSize: '12px', // fontSizeBase200
    color: '#616161', // colorNeutralForeground3
    '@media (max-width: 744px)': {
      display: 'none'
    }
  })
}

interface IChatListSectionProps {
  header?: string
  conversations: Conversations
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({
  header,
  conversations
}) => {
  const { selectedId } = useConversations()
  const keys = Object.keys(conversations)

  return keys.length > 0 ? (
    <div css={classes.root}>
      <Text css={classes.header}>{header}</Text>
      {keys.map((id) => {
        const convo = conversations[id]
        const messages = convo.messages
        const lastMessage = messages[convo.messages.length - 1]
        const isSelected = id === selectedId
        return (
          <ChatListItem
            id={id}
            key={id}
            isSelected={isSelected}
            header={getFriendlyChatName(convo)}
            timestamp={convo.lastUpdatedTimestamp ?? lastMessage.timestamp}
            preview={
              messages.length > 0
                ? lastMessage.type === ChatMessageType.Document
                  ? 'Sent a file'
                  : lastMessage.content
                : 'Click to start the chat'
            }
            botProfilePicture={convo.botProfilePicture}
          />
        )
      })}
    </div>
  ) : null
}
