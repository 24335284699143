import { css } from '@emotion/react'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeAlert, useApp } from '../../redux/features/app/appSlice'

const classes = {
  alert: css({
    fontWeight: '400', //fontWeightRegular
    color: '#242424', // colorNeutralForeground1
    backgroundColor: '#e6e6e6', // colorNeutralBackground6
    fontSize: '10px', // fontSizeBase200
    lineHeight: '12px' // lineHeightBase200
  }),
  actionItems: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  }),
  button: css({
    alignSelf: 'center'
  })
}

export const Alerts: React.FC = () => {
  const dispatch = useDispatch()
  const { alerts } = useApp()
  const messageTypes = [
    {
      key: 'info',
      value: MessageBarType.info
    },
    {
      key: 'error',
      value: MessageBarType.error
    },
    {
      key: 'warning',
      value: MessageBarType.warning
    },
    {
      key: 'success',
      value: MessageBarType.success
    }
  ]

  return (
    <div>
      {alerts.map(({ type, message, onRetry }, index) => {
        return (
          <>
            <MessageBar
              messageBarType={
                messageTypes.find((mtype) => mtype.key === type)?.value
              }
              actions={onRetry && <div onClick={onRetry}>Retry</div>}
              dismissButtonAriaLabel="Close"
              onDismiss={() => {
                dispatch(removeAlert(index))
              }}
              key={`${index}-${type}`}
              css={classes.alert}
            >
              {message.slice(0, 1000) + (message.length > 1000 ? '...' : '')}
            </MessageBar>
          </>
        )
      })}
    </div>
  )
}
