import { css } from '@emotion/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { IChatMessage } from '../../../libs/models/ChatMessage'
// import * as utils from './../../utils/TextUtils';

const classes = {
  content: css({
    wordBreak: 'break-word'
  })
}

interface ChatHistoryTextContentProps {
  message: IChatMessage
}

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({
  message
}) => {
  // const content = utils.replaceCitationLinksWithIndices(utils.formatChatTextContent(message.content), message);
  const content = message.content
  return (
    <div css={classes.content}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </div>
  )
}
