import { AlertType } from '../../../libs/models/AlertType'
import { IChatUser } from '../../../libs/models/ChatUser'

// This is the default user information when authentication is set to 'None'.
// It must match what is defined in PassthroughAuthenticationHandler.cs on the backend.
export const DefaultChatUser: IChatUser = {
  id: 'c05c61eb-65e4-4223-915a-fe72b0c9ece1',
  emailAddress: 'user@contoso.com',
  fullName: 'Default User',
  online: true,
  isTyping: false
}

export const DefaultActiveUserInfo: ActiveUserInfo = {
  id: DefaultChatUser.id,
  email: DefaultChatUser.emailAddress,
  username: DefaultChatUser.fullName
}

export interface ActiveUserInfo {
  id: string
  email: string
  username: string
}

export interface Alert {
  message: string
  type: AlertType
  id?: string
  onRetry?: () => void
}

interface Feature {
  enabled: boolean // Whether to show the feature in the UX
  label: string
  inactive?: boolean // Set to true if you don't want the user to control the visibility of this feature or there's no backend support
  description?: string
}

export interface Setting {
  title: string
  description?: string
  features: FeatureKeys[]
}

export interface AppState {
  alerts: Alert[]
  activeUserInfo?: ActiveUserInfo
  features: Record<FeatureKeys, Feature>
  settings: Setting[]
}

export enum FeatureKeys {
  DarkMode,
  SimplifiedExperience
}

export const Features = {
  [FeatureKeys.DarkMode]: {
    enabled: false,
    label: 'Dark Mode'
  },
  [FeatureKeys.SimplifiedExperience]: {
    enabled: true,
    label: 'Simplified Chat Experience'
  }
}

export const Settings = [
  {
    // Basic settings has to stay at the first index. Add all new settings to end of array.
    title: 'Basic',
    features: [FeatureKeys.DarkMode],
    stackVertically: true
  },
  {
    title: 'Display',
    features: [FeatureKeys.SimplifiedExperience],
    stackVertically: true
  }
]

export const initialState: AppState = {
  alerts: [],
  activeUserInfo: DefaultActiveUserInfo,
  features: Features,
  settings: Settings
}
