import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip'
import { useId } from '@fluentui/react-hooks'
import React from 'react'
import { DeleteChatDialog } from './dialogs/DeleteChatDialog'

const classes = {
  root: css({
    display: 'contents',
    '@media (max-width: 744px)': {
      display: 'none'
    }
  })
}

const calloutProps = { gapSpace: 0 }
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' }
}
const editIcon: IIconProps = { iconName: 'Edit' }

interface IListItemActionsProps {
  chatId: string
  onEditTitleClick: () => void
}

export const ListItemActions: React.FC<IListItemActionsProps> = ({
  chatId,
  onEditTitleClick
}) => {
  const editTooltipId = useId('editTooltip')

  return (
    <div css={classes.root}>
      <TooltipHost
        content="Edit chat name"
        id={editTooltipId}
        styles={hostStyles}
        calloutProps={calloutProps}
      >
        <IconButton
          iconProps={editIcon}
          title="Edit chat name"
          aria-label="Edit chat name"
          onClick={onEditTitleClick}
          data-testid="editChatTitleButtonSimplified"
        />
      </TooltipHost>
      <DeleteChatDialog chatId={chatId} />
    </div>
  )
}
