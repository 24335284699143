import { css } from '@emotion/react'
import React from 'react'
import { IChatMessage } from '../../../libs/models/ChatMessage'
import { ChatHistoryItem } from './ChatHistoryItem'

const classes = {
  root: css({
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '105em',
    width: '100%',
    justifySelf: 'center'
  }),
  item: css({
    display: 'flex',
    flexDirection: 'column'
  })
}

interface ChatHistoryProps {
  messages: IChatMessage[]
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
  return (
    <div css={classes.root}>
      {messages.map((message, index) => (
        <ChatHistoryItem
          key={message.timestamp}
          message={message}
          messageIndex={index}
        />
      ))}
    </div>
  )
}
