import { css } from '@emotion/react'
import React from 'react'
import { useConversations } from '../../redux/features/conversations/conversationsSlice'
import { TypingIndicator } from './typing-indicator/TypingIndicator'

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'row'
  })
}

export const ChatStatus: React.FC = () => {
  const { conversations, selectedId } = useConversations()
  const message = conversations[selectedId].botResponseStatus

  if (!message) {
    return null
  }

  return (
    <div css={classes.root}>
      <label style={{ whiteSpace: 'pre' }}> {message} </label>
      <TypingIndicator />
    </div>
  )
}
