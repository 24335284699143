import { css } from '@emotion/react'
import {
  DefaultButton,
  IconButton,
  PrimaryButton
} from '@fluentui/react/lib/Button'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { IIconProps } from '@fluentui/react/lib/Icon'
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip'
import { useId } from '@fluentui/react-hooks'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getFriendlyChatName, useChat } from '../../../../libs/hooks/useChat'
import { useConversations } from '../../../../redux/features/conversations/conversationsSlice'

const classes = {
  root: css({
    width: '100%'
  }),
  actions: css({
    paddingTop: '10%'
  })
}

const calloutProps = { gapSpace: 0 }
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' }
}

interface IEditChatNameProps {
  chatId: string
}

const dialogStyles = { main: { maxWidth: 450 } }

const deleteIcon: IIconProps = { iconName: 'Delete' }

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId }) => {
  const { selectedHouseholdId } = useRdot360Context()
  const tooltipId: string = useId('tooltip')
  const chat = useChat()

  const { conversations } = useConversations()
  const location = useLocation()
  const chatName = getFriendlyChatName(conversations[chatId])

  const [hideDialog, toggleHideDialog] = useState(true)

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const dialogContentProps = {
    type: DialogType.normal,
    title: `Are you sure you want to delete chat: ${chatName}?`,
    closeButtonAriaLabel: 'Close',
    subText:
      'This action will permanently delete the chat, and any associated resources and memories, for all participants, including RCopilot.'
  }

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles
    }),
    [labelId, subTextId]
  )

  const onDeleteChat = () => {
    void chat.deleteChat(chatId, location.pathname, selectedHouseholdId ?? '')
  }

  return (
    <>
      <TooltipHost
        content="Delete chat session"
        id={tooltipId}
        styles={hostStyles}
        calloutProps={calloutProps}
      >
        <IconButton
          iconProps={deleteIcon}
          aria-label="Delete chat session"
          title="Delete chat session"
          onClick={() => toggleHideDialog(false)}
        />
      </TooltipHost>
      <Dialog
        css={classes.root}
        hidden={hideDialog}
        onDismiss={() => toggleHideDialog(true)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton onClick={() => toggleHideDialog(true)}>
            Cancel
          </DefaultButton>
          <PrimaryButton onClick={onDeleteChat}>Delete</PrimaryButton>
        </DialogFooter>
      </Dialog>
    </>
  )
}
