import { css } from '@emotion/react'
import { useState, FC } from 'react'
import { ChatList } from './chat-list/ChatList'
import { ChatWindow } from './ChatWindow'

const classes = {
  container: css({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'start',
    height: '100%'
  })
}

export const ChatView: FC = () => {
  const [chatListIsOpen, setChatListIsOpen] = useState(false)

  const toggleChatList = () => {
    setChatListIsOpen(!chatListIsOpen)
  }

  return (
    <div css={classes.container}>
      {chatListIsOpen && <ChatList toggleChatList={toggleChatList} />}
      <ChatWindow
        chatListIsOpen={chatListIsOpen}
        toggleChatList={toggleChatList}
      />
    </div>
  )
}
