import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip'
import { useId } from '@fluentui/react-hooks'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useChat } from '../../../../libs/hooks/useChat'

const addIcon: IIconProps = { iconName: 'Add' }
const calloutProps = { gapSpace: 0 }
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' }
}

interface SimplifiedNewBotMenuProps {
  toggleChatList: () => void
}

export const SimplifiedNewBotMenu: FC<SimplifiedNewBotMenuProps> = ({
  toggleChatList
}) => {
  const { selectedHouseholdId } = useRdot360Context()
  const chat = useChat()
  const tooltipId = useId('tooltip')
  const location = useLocation()

  const onAddChat = () => {
    chat.createChat(location.pathname, selectedHouseholdId ?? '')
  }

  const handleAddChat = () => {
    onAddChat()
    toggleChatList()
  }

  return (
    <div>
      <TooltipHost
        content="Create new conversation"
        id={tooltipId}
        styles={hostStyles}
        calloutProps={calloutProps}
      >
        <IconButton
          iconProps={addIcon}
          title="Create new conversation"
          ariaLabel="Create new conversation"
          onClick={() => handleAddChat()}
          onRenderMenuIcon={() => null}
        />
      </TooltipHost>
    </div>
  )
}
