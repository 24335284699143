import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import {
  useDatahubApiUtil,
  useGetRevenueByCategorySummaryQuery,
  useGetRevenueDetailedSummaryByAccountQuery,
  useGetRevenueDetailsTableQuery,
  useGetRevenueHistoryByAccountQuery
} from '../../store/datahub'
import { useRdot360AccountContext } from '../../store/rdot360Context'

export const useGetRevenueSummaryData = () => {
  const { selectedAccountIds } = useRdot360AccountContext()
  const skip = !selectedAccountIds?.length
  const { data, isFetching, error, isUninitialized } =
    useGetRevenueDetailedSummaryByAccountQuery(
      skip ? skipToken : selectedAccountIds
    )
  return {
    data: skip || !!error ? undefined : data,
    isFetching,
    isUninitialized
  }
}

export const useGetRevenueSummaryChartData = (
  startDate: string,
  endDate: string,
  categoryType?: string
) => {
  const { selectedAccountIds } = useRdot360AccountContext()
  const skip = !selectedAccountIds?.length
  const { data, isFetching, error, isUninitialized } =
    useGetRevenueHistoryByAccountQuery(
      skip
        ? skipToken
        : {
            accounts: selectedAccountIds || [],
            startDate: startDate,
            endDate: endDate,
            categoryType: categoryType ?? 'summary'
          }
    )

  const { invalidateTags } = useDatahubApiUtil()
  const invalidateCache = useCallback(
    () => invalidateTags(['rdot360Revenue']),
    [invalidateTags]
  )
  return {
    data: skip || !!error ? undefined : data,
    isFetching,
    isUninitialized,
    invalidateCache,
    error
  }
}

export const useGetRevenueTableData = (startDate: string, endDate: string) => {
  const { selectedAccountIds } = useRdot360AccountContext()
  const skip = !selectedAccountIds?.length
  const { data, isFetching, error, isUninitialized } =
    useGetRevenueByCategorySummaryQuery(
      skip
        ? skipToken
        : {
            accounts: selectedAccountIds || [],
            startDate: startDate,
            endDate: endDate
          }
    )
  return {
    data: skip || !!error ? undefined : data,
    isFetching,
    isUninitialized
  }
}

export const useGetRevenueDetailTableData = (
  startDate: string,
  endDate: string
) => {
  const { selectedAccountIds } = useRdot360AccountContext()
  const skip = !selectedAccountIds?.length
  const { data, isFetching, error, isUninitialized } =
    useGetRevenueDetailsTableQuery(
      skip
        ? skipToken
        : {
            accounts: selectedAccountIds || [],
            startDate: startDate,
            endDate: endDate
          }
    )
  const { invalidateTags } = useDatahubApiUtil()
  const invalidateCache = useCallback(
    () => invalidateTags(['rdot360Revenue']),
    [invalidateTags]
  )

  return {
    data: skip || !!error ? undefined : data,
    isFetching,
    isUninitialized,
    invalidateCache,
    error
  }
}
