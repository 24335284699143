import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { RdotAccountCategoryCode } from 'api/account.types'
import {
  intersectionWith,
  sumBy,
  keyBy,
  values,
  fromPairs,
  groupBy
} from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { BalanceItem } from '../../components/Balances/BalanceSummaryTableColumns'
import {
  balancesApi,
  useBalancesApiUtil,
  useGetBalancesDetailQuery
} from '../balancesApi'
import {
  IBalanceDetailResponseValueItem,
  IBalanceTodaysChangeItem,
  todaysChangeFields
} from '../balancesApi/IBalanceDetailResponse'
import { selectApiKey, selectContextId } from './apiContext'
import {
  selectAccountLookupByAccountIdOrKey,
  selectSelectedAccountKeys,
  useRdot360AccountContext
} from './useRdot360AccountContext'

export interface IRdot360BalanceSummary {
  totalAccountValue?: number
  totalAccountValueChange?: number
}

type BalancePropsToAggregate = keyof Pick<
  IBalanceDetailResponseValueItem,
  | 'annuity'
  | 'annuitychange'
  | 'availablemarginbalance'
  | 'availablemarginbalancechange'
  | 'availablewithdraw'
  | 'cashbuyingpower'
  | 'cashmarginbuyingpower'
  | 'cashbalance'
  | 'cashbalancechange'
  | 'cashmoneyaccounts'
  | 'cashmoneyaccountschange'
  | 'moneyaccountvalue'
  | 'moneyaccountvaluechange'
  | 'fixedincomeaccruedinterest'
  | 'fixedincomeaccruedinterestchange'
  | 'holdingAccruIncome'
  | 'totalaccountvalue'
  | 'totalaccountvaluechange'
  | 'pricedinvestments'
  | 'pricedinvestmentschange'
  | 'pendingactivity'
  | 'othercreditdebit'
  | 'othercreditdebitchange'
  | 'cashmarginavailabletowithdraw'
  | 'marginandoutstandingbalance'
  | 'outstandingbalancechange'
  | 'marginbalancechange'
  | 'todayschange'
  | 'outstandingbalance'
  | 'totalassetaccountvalue'
  | 'totalassetaccountvaluechange'
>

const balancePropsToAggregate: BalancePropsToAggregate[] = [
  'annuity',
  'annuitychange',
  'availablemarginbalance',
  'availablemarginbalancechange',
  'availablewithdraw',
  'cashbuyingpower',
  'cashmarginbuyingpower',
  'cashbalance',
  'cashbalancechange',
  'cashmoneyaccounts',
  'cashmoneyaccountschange',
  'moneyaccountvalue',
  'moneyaccountvaluechange',
  'fixedincomeaccruedinterest',
  'fixedincomeaccruedinterestchange',
  'holdingAccruIncome',
  'totalaccountvalue',
  'totalaccountvaluechange',
  'pricedinvestments',
  'pricedinvestmentschange',
  'pendingactivity',
  'othercreditdebit',
  'othercreditdebitchange',
  'cashmarginavailabletowithdraw',
  'marginandoutstandingbalance',
  'outstandingbalancechange',
  'marginbalancechange',
  'outstandingbalance',
  'totalassetaccountvalue',
  'totalassetaccountvaluechange'
]

export type EnhancedBalanceDetailResponseValue = ReturnType<
  typeof mapToNewBalance
>
const mapToNewBalance = (balance: IBalanceDetailResponseValueItem) => {
  const totalAssets = balance.totalassetaccountvalue || 0
  const totalAssetsChange = balance.totalassetaccountvaluechange || 0
  const totalLiabilities = balance.outstandingbalance || 0
  const totalLiabilitiesChange = balance.outstandingbalancechange || 0
  const cash = balance.cashmoneyaccounts || 0
  const cashChange = balance.cashmoneyaccountschange || 0
  const netWorth = balance.totalaccountvalue || 0
  const netWorthChange = balance.totalaccountvaluechange || 0
  const availableToWithdraw =
    balance.cashmarginavailabletowithdraw || balance.availablewithdraw || 0
  const availableToInvest =
    balance.cashmarginbuyingpower || balance.cashbuyingpower || 0
  const other = balance.othercreditdebit || 0
  const otherChange = balance.othercreditdebitchange || 0

  return {
    ...balance,
    cash,
    cashChange,
    totalAssets,
    totalAssetsChange,
    totalLiabilities,
    totalLiabilitiesChange,
    netWorth,
    netWorthChange,
    availableToWithdraw,
    availableToInvest,
    other,
    otherChange
  }
}

const defaultTodaysChangeData: IBalanceTodaysChangeItem = {
  category: '',
  Activity: [],
  sortOrder: 1,
  amount: 0
}

const emptyTodaysChangeData = {
  [todaysChangeFields.gainOrLoss]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.gainOrLoss
  },
  [todaysChangeFields.accruedIncome]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.accruedIncome
  },
  [todaysChangeFields.deposits]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.deposits
  },
  [todaysChangeFields.withdrawals]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.withdrawals
  },
  [todaysChangeFields.interestOrDividends]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.interestOrDividends
  },
  [todaysChangeFields.other]: {
    ...defaultTodaysChangeData,
    category: todaysChangeFields.other
  }
}

type BalanceDataDetails =
  | IBalanceDetailResponseValueItem
  | BalanceItem
  | EnhancedBalanceDetailResponseValue

export const getTodaysChange = (balances: BalanceDataDetails[]) => {
  const categoryData = groupBy(
    balances?.flatMap((item) => item?.todayschange?.RCM || []),
    (x) => x.category
  )
  const balancesTodaysChange = fromPairs(
    Object.entries(emptyTodaysChangeData).map(([cat, data]) => {
      const amount = sumBy(categoryData[cat], 'amount') || 0
      return [cat, { ...data, amount }]
    })
  )

  return values(balancesTodaysChange)
}

const getBalanceSummary = (balances: IBalanceDetailResponseValueItem[]) => {
  const [first] = balances || []
  const summary = balancePropsToAggregate.reduce(
    (a, prop) => ({
      ...a,
      [prop]: sumBy(balances, (x) => (x?.[prop] as number) || 0)
    }),
    {} as Pick<IBalanceDetailResponseValueItem, BalancePropsToAggregate>
  )

  summary.todayschange = { RCM: values(getTodaysChange(balances)) }

  const newBalance = mapToNewBalance(summary)

  return {
    ...newBalance,
    asofdate: first?.asofdate
  }
}

const selectBalancesRequest = createSelector(
  [selectApiKey, selectContextId],
  (apiKey, contextId) =>
    contextId
      ? {
          contextId: contextId,
          contextSessionKey: apiKey
        }
      : skipToken
)

const selectBalancesApiResultSelector = createSelector(
  [selectBalancesRequest],
  (request) => balancesApi.endpoints.getBalancesDetail.select(request)
)

const selectBalancesApiResult = (state: AppState) =>
  selectBalancesApiResultSelector(state)(state as any)

const selectIsLoading = createSelector(
  [selectBalancesApiResult],
  ({ isLoading }) => isLoading
)

const selectIsUninitialized = createSelector(
  [selectBalancesApiResult],
  ({ isUninitialized }) => isUninitialized
)

const selectError = createSelector(
  [selectBalancesApiResult],
  ({ error }) => error
)

const selectBalancesApiResultData = createSelector(
  [selectBalancesApiResult],
  (result) => result.data
)

const selectAccountBalances = createSelector(
  [selectBalancesApiResultData, selectAccountLookupByAccountIdOrKey],
  (balancesResponse, accountLookupByAccountIdOrKey) =>
    balancesResponse?.accountbalances
      ?.filter((x) => x.key && accountLookupByAccountIdOrKey[x.key])
      .map(mapToNewBalance) || []
)

const selectSelectedAccountBalances = createSelector(
  [selectAccountBalances, selectSelectedAccountKeys],
  (householdAccountBalances, selectedAccountKeys) =>
    intersectionWith(
      householdAccountBalances,
      selectedAccountKeys,
      (a, b) => a.key === b
    )
)

const selectSelectedBrokerageAccounts = createSelector(
  [selectSelectedAccountBalances, selectAccountLookupByAccountIdOrKey],
  (selectedAccountBalances, accountLookupByAccountIdOrKey) =>
    selectedAccountBalances?.filter(
      (x) =>
        x?.key &&
        accountLookupByAccountIdOrKey[x.key]?.accounttype === 'Brokerage'
    )
)
const selectCashInBrokerageAccounts = createSelector(
  [selectSelectedBrokerageAccounts],
  (brokerageAccounts) => sumBy(brokerageAccounts, (x) => x.cash || 0)
)

const selectAccountsGroupedByRCMCategory = createSelector(
  [selectAccountBalances, selectAccountLookupByAccountIdOrKey],
  (balances, lookup) =>
    groupBy(
      balances,
      (x) => (x?.key && lookup[x.key]?.RDOTAccountCategoryCode) || '04'
    ) as Record<RdotAccountCategoryCode, BalanceItem[]>
)

const selectSelectedAccountsGroupedByRCMCategory = createSelector(
  [selectSelectedAccountBalances, selectAccountLookupByAccountIdOrKey],
  (balances, lookup) =>
    groupBy(
      balances,
      (x) => (x?.key && lookup[x.key]?.RDOTAccountCategoryCode) || '04'
    ) as Record<RdotAccountCategoryCode, BalanceItem[]>
)

const selectAccountCategoryBalanceChanges = createSelector(
  [selectAccountsGroupedByRCMCategory],
  (groups) =>
    fromPairs(
      Object.entries(groups).map(([key, value]) => [
        key,
        sumBy(value, ({ netWorthChange }) => netWorthChange || 0)
      ])
    ) as Record<RdotAccountCategoryCode, number>
)

export const selectSelectedAccountCategoryBalanceChanges = createSelector(
  [selectSelectedAccountsGroupedByRCMCategory],
  (groups) =>
    fromPairs(
      Object.entries(groups).map(([key, value]) => [
        key,
        sumBy(value, ({ netWorthChange }) => netWorthChange || 0)
      ])
    ) as Record<RdotAccountCategoryCode, number>
)

const selectHouseholdAccountBalanceSummary = createSelector(
  [selectAccountBalances, selectAccountCategoryBalanceChanges],
  (householdAccountBalances, changeByGroup) => ({
    ...getBalanceSummary(householdAccountBalances),
    totalNonNfsValueChange: changeByGroup['02'],
    totalexternalaccountvaluechange: changeByGroup['03'],
    totalotherassetsvaluechange: changeByGroup['04']
  })
)

const selectSelectedAccountBalanceSummary = createSelector(
  [selectSelectedAccountBalances, selectSelectedAccountCategoryBalanceChanges],
  (selectedAccountBalances, changeByGroup) => ({
    ...getBalanceSummary(selectedAccountBalances),
    totalNonNfsValueChange: changeByGroup['02'],
    totalexternalaccountvaluechange: changeByGroup['03'],
    totalotherassetsvaluechange: changeByGroup['04']
  })
)

export const selectBalanceLookupByKey = createSelector(
  [selectAccountBalances],
  (householdAccountBalances) =>
    keyBy(householdAccountBalances, (x) => x.key || '')
)

const emptyResult = { static: 'useGetBalancesDetailQuery' }
export const useRdot360BalancesContext = () => {
  const balancesRequest = useSelector(selectBalancesRequest)
  const { refetch } = useGetBalancesDetailQuery(balancesRequest, {
    selectFromResult: () => emptyResult
  })

  const { isMultiMarginInSelectedAccounts } = useRdot360AccountContext()
  const { invalidateTags } = useBalancesApiUtil()
  const householdAccountBalances = useSelector(selectAccountBalances)
  const selectedAccountBalances = useSelector(selectSelectedAccountBalances)
  const cashInSelectedBrokerageAccounts = useSelector(
    selectCashInBrokerageAccounts
  )
  const selectedAccountBalanceSummary = useSelector(
    selectSelectedAccountBalanceSummary
  )
  const householdAccountBalanceSummary = useSelector(
    selectHouseholdAccountBalanceSummary
  )
  const balanceLookupByKey = useSelector(selectBalanceLookupByKey)
  const isFetching = useSelector(selectIsLoading)
  const error = useSelector(selectError)
  const isUninitialized = useSelector(selectIsUninitialized)

  const invalidateCache = useCallback(
    () => invalidateTags(['balancesDetail']),
    [invalidateTags]
  )

  return {
    isFetching,
    error,
    refetch,
    selectedAccountBalances,
    selectedAccountBalanceSummary,
    householdAccountBalances,
    householdAccountBalanceSummary,
    invalidateCache,
    cashInSelectedBrokerageAccounts,
    balanceLookupByKey,
    isMultiMarginInSelectedAccounts,
    isUninitialized
  }
}
