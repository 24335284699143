import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { AppState as RootAppState } from 'store'
import { Constants } from '../../../Constants'
import {
  ActiveUserInfo,
  Alert,
  AppState,
  FeatureKeys,
  initialState
} from './AppState'

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAlerts: (state: AppState, action: PayloadAction<Alert[]>) => {
      state.alerts = action.payload
    },
    addAlert: (state: AppState, action: PayloadAction<Alert>) => {
      if (
        action.payload.id === Constants.app.CONNECTION_ALERT_ID ||
        isServerConnectionError(action.payload.message)
      ) {
        updateConnectionStatus(state, action.payload)
      } else {
        addNewAlert(state.alerts, action.payload)
      }
    },
    removeAlert: (state: AppState, action: PayloadAction<number>) => {
      state.alerts.splice(action.payload, 1)
    },
    setActiveUserInfo: (
      state: AppState,
      action: PayloadAction<ActiveUserInfo>
    ) => {
      state.activeUserInfo = action.payload
    },
    // This sets the feature flag based on end user input
    toggleFeatureFlag: (
      state: AppState,
      action: PayloadAction<FeatureKeys>
    ) => {
      const feature = state.features[action.payload]
      state.features = {
        ...state.features,
        [action.payload]: {
          ...feature,
          enabled: !feature.enabled
        }
      }
    },
    // This controls feature availability based on the state of backend
    toggleFeatureState: (
      state: AppState,
      action: PayloadAction<{
        feature: FeatureKeys
        deactivate: boolean
        enable: boolean
      }>
    ) => {
      const feature = state.features[action.payload.feature]
      state.features = {
        ...state.features,
        [action.payload.feature]: {
          ...feature,
          enabled: action.payload.deactivate ? false : action.payload.enable,
          inactive: action.payload.deactivate
        }
      }
    }
  }
})

export const {
  addAlert,
  removeAlert,
  setAlerts,
  setActiveUserInfo,
  toggleFeatureFlag,
  toggleFeatureState
} = appSlice.actions

export default appSlice.reducer

const isServerConnectionError = (message: string) => {
  return (
    message.includes(
      `Cannot send data if the connection is not in the 'Connected' State.`
    ) ||
    message.includes(
      `Server timeout elapsed without receiving a message from the server.`
    )
  )
}

const addNewAlert = (alerts: Alert[], newAlert: Alert) => {
  if (alerts.length === 3) {
    alerts.shift()
  }
  alerts.push(newAlert)
}
const updateConnectionStatus = (state: AppState, statusUpdate: Alert) => {
  if (isServerConnectionError(statusUpdate.message)) {
    statusUpdate.message =
      // Constant message so alert UI doesn't feel glitchy on every connection error from SignalR
      'Cannot send data due to lost connection or server timeout. Try refreshing this page to restart the connection.'
  }

  // There should only ever be one connection alert at a time,
  // so we tag the alert with a unique ID so we can remove if needed
  statusUpdate.id ??= Constants.app.CONNECTION_ALERT_ID

  // Remove the existing connection alert if it exists
  const connectionAlertIndex = state.alerts.findIndex(
    (alert: Alert) => alert.id === Constants.app.CONNECTION_ALERT_ID
  )
  if (connectionAlertIndex !== -1) {
    state.alerts.splice(connectionAlertIndex, 1)
  }

  addNewAlert(state.alerts, statusUpdate)
}

interface Feature {
  enabled: boolean // Whether to show the feature in the UX
  label: string
  inactive?: boolean // Set to true if you don't want the user to control the visibility of this feature or there's no backend support
  description?: string
}

const rootSelector = (state: RootAppState) =>
  state.modules.advisory.modules.copilot.app

export const getFeatures = flow(rootSelector, (x) => x.features)
export const getAlerts = flow(rootSelector, (x) => x.alerts)
export const getActiveUserInfo = flow(rootSelector, (x) => x.activeUserInfo)

export const useApp = () => {
  const features: Record<FeatureKeys, Feature> = useSelector(getFeatures)
  const alerts: Alert[] = useSelector(getAlerts)
  const activeUserInfo = useSelector(getActiveUserInfo)
  return {
    features,
    alerts,
    activeUserInfo
  }
}
