import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { getRockefellerApiConfig } from 'store/system'
import { Constants } from '../../Constants'

export const useAuth = () => {
  const apiConfig = useSelector(getRockefellerApiConfig)

  const apiScopes = apiConfig?.scopes ?? []
  const apiBaseUrl = apiConfig?.root ?? window.location.origin
  const msalScopes = Constants.msal.semanticKernelScopes.concat(apiScopes ?? [])

  const getToken = useCallback(async () => {
    const accessToken = await tryAcquireAccessToken(msalScopes ?? [])
    return accessToken
  }, [msalScopes])

  return { apiConfig, apiBaseUrl, getToken }
}
