import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useEffect, useMemo } from 'react'
import { useCopilotMemory } from 'store/api/rcopilot'
import {
  IncomeApiMode,
  PositionCategoryType,
  useGetCategoryPositionQuery,
  useGetHistoricalIncomeChartQuery,
  useGetIncomeDetailBySecurityQuery,
  useGetIncomeSummaryQuery,
  useGetIncomeTaxSummaryQuery,
  useGetPositionByCategoryQuery,
  useGetProjectedIncomeChartQuery,
  useGetTop10HoldingsQuery
} from '../holdingsApi'
import { useRdot360SelectedAccountsApiContext } from './apiContext'
import { getFixedResult } from './shared'

const useHoldingsApiSelectedAccountsBaseRequest = () => {
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()
  return apiContextAccounts?.length
    ? {
        accounts: apiContextAccounts,
        contextId: apiKey
      }
    : false
}

export const useGetTop10HoldingsQueryForSelectedAccounts = () => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetTop10HoldingsQuery(request || skipToken)
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetPositionByCategoryQueryForSelectedAccounts = (
  category: PositionCategoryType
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetPositionByCategoryQuery(
    request ? { ...request, category } : skipToken
  )
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetCategoryPositionQueryForSelectedAccounts = (
  category: PositionCategoryType = 'L1'
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetCategoryPositionQuery(
    request ? { ...request, category } : skipToken
  )
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetHistoricalIncomeChartQueryForSelectedAccounts = (
  financialYear?: number
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetHistoricalIncomeChartQuery(
    request && financialYear ? { ...request, financialYear } : skipToken
  )
  const { data, isFetching, isUninitialized } = result
  const { addObjectToMemory } = useCopilotMemory()
  const { selectedHouseholdId } = useRdot360Context()
  useEffect(() => {
    if (isFetching || isUninitialized) {
      return
    }
    addObjectToMemory(
      data,
      'copilot-client-dashboard',
      ['income'],
      selectedHouseholdId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addObjectToMemory, data, isFetching, isUninitialized])
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetIncomeSummaryQueryForSelectedAccounts = () => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetIncomeSummaryQuery(request || skipToken)
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetProjectedIncomeChartQueryForSelectedAccounts = (
  financialYear?: number
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetProjectedIncomeChartQuery(
    request && financialYear ? { ...request, financialYear } : skipToken
  )
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetIncomeDetailBySecurityQueryForSelectedAccounts = (
  type: IncomeApiMode,
  financialYear?: number
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetIncomeDetailBySecurityQuery(
    request && financialYear ? { ...request, type, financialYear } : skipToken
  )
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}

export const useGetIncomeTaxSummaryQueryForSelectedAccounts = (
  type: IncomeApiMode,
  financialYear?: number
) => {
  const request = useHoldingsApiSelectedAccountsBaseRequest()
  const result = useGetIncomeTaxSummaryQuery(
    request && financialYear ? { ...request, financialYear, type } : skipToken
  )
  const fixedResult = useMemo(
    () => getFixedResult(request, result),
    [request, result]
  )
  return fixedResult
}
