import { css } from '@emotion/react'
import { Image } from '@fluentui/react/lib/Image'
import React from 'react'
import typingBalls from '../../../assets/typing-balls-light.svg'

const classes = {
  root: css({
    contain: 'strict',
    height: '28px',
    overflowY: 'hidden',
    width: '28px'
  })
}

export const TypingIndicator: React.FC = () => {
  return (
    <div css={classes.root}>
      <Image role="presentation" src={typingBalls} />
    </div>
  )
}
