import { css } from '@emotion/react'
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona'
import { Text, ITextProps } from '@fluentui/react/lib/Text'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice'
import { timestampToDateString } from '../../utils/TextUtils'
// import { EditChatName } from './dialogs/EditChatName'
import { ListItemActions } from './ListItemActions'

const classes = {
  root: css({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '@media (max-width: 744px)': {
      justifyContent: 'center'
    },
    cursor: 'pointer',
    padding: '12px 14px'
  }),
  avatar: css({
    flexShrink: 0,
    width: '32px'
  }),
  body: css({
    minWidth: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '4px',
    '@media (max-width: 744px)': {
      display: 'none'
    },
    alignSelf: 'center'
  }),
  header: css({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }),
  title: css({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap', //overflow ellipses
    fontSize: '12px', // fontSizeBase300
    color: '#242424' // colorNeutralForeground1
  }),
  timestamp: css({
    flexShrink: 0,
    marginLeft: '12px',
    fontSize: '10px', // fontSizeBase200
    color: '#424242', // colorNeutralForeground2
    lineHeight: '16px' // lineHeightBase200
  }),
  previewText: css({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap', //overflow ellipses
    display: 'block',
    lineHeight: '14px', // lineHeightBase100
    color: '#424242', // colorNeutralForeground2
    fontSize: '10px'
  }),
  popoverSurface: css({
    display: 'none',
    '@media (max-width: 744px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  }),
  selected: css({
    backgroundColor: '#ffffff' // colorNeutralBackground1
  }),
  protectedIcon: css({
    color: '#a7e3a5', // colorPaletteLightGreenBorder1
    verticalAlign: 'text-bottom',
    marginLeft: '4px'
  })
}

interface IChatListItemProps {
  id: string
  header: string
  timestamp: number
  preview: string
  botProfilePicture: string
  isSelected: boolean
}

export const ChatListItem: FC<IChatListItemProps> = ({
  id,
  header,
  timestamp,
  preview,
  botProfilePicture,
  isSelected
}) => {
  const dispatch = useDispatch()

  const showPreview = preview
  const showActions = isSelected

  // const [editingTitle, setEditingTitle] = useState(false)
  const onClick = (_ev: any) => {
    _ev.stopPropagation()
    dispatch(setSelectedConversation(id))
  }

  const time = timestampToDateString(timestamp)
  return (
    <div css={[classes.root, isSelected && classes.selected]} onClick={onClick}>
      <Persona imageUrl={botProfilePicture} size={PersonaSize.size24} />
      {/* {editingTitle ? (
        <EditChatName
          name={header}
          chatId={id}
          exitEdits={() => {
            setEditingTitle(false);
          }}
        />
      ) : (
        <> */}
      <div css={classes.body}>
        <div css={classes.header}>
          <Text css={classes.title} title={header}>
            {header}
          </Text>
          <Text
            css={classes.timestamp}
            variant={'medium' as ITextProps['variant']}
          >
            {time}
          </Text>
        </div>
        {showPreview && (
          <>
            {
              <Text
                id={`message-preview-${id}`}
                variant={'small' as ITextProps['variant']}
                css={classes.previewText}
              >
                {preview}
              </Text>
            }
          </>
        )}
      </div>
      {showActions && (
        <ListItemActions
          chatId={id}
          onEditTitleClick={() => {
            // setEditingTitle(true)
          }}
        />
      )}
      {/* </>
      )} */}
    </div>
  )
}
