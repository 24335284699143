import { convertColumnTypeToFilterType } from 'features/DataList/service'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { AppState } from 'store'
import { fetchRetirementFunds } from '../../shared/retirementService'
import { retirementPlanColumns } from './retirementPlanColumns'

export const defaultColumnsIds = retirementPlanColumns.map((x) => x.name)

const uiFilters = keyBy(
  retirementPlanColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const store = createOdataListWithFacetsStore({
  prefix: '@modules/@advisory/@modules/@retirement/@plans',
  getOdataResults: fetchRetirementFunds,
  initialState: {
    data: {},
    ui: {
      columns: retirementPlanColumns,
      selectedColumns: defaultColumnsIds.slice(1),
      filters: uiFilters,
      sortBy: {
        direction: 'desc',
        name: 'Client Live Date'
      }
    },
    facets: {}
  },
  rootSelector: (state: AppState) =>
    state.modules.advisory.modules.retirement.plans
})

export const { actions, selectors, reducer, sagas } = store
