import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import React from 'react'
import { ChatRoom } from './ChatRoom'

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#f5f5f5', //colorNeutralBackground3
    boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem'
  }),
  header: css({
    borderBottom: '1px solid rgb(0 0 0 / 10%)',
    padding: '8px 12px',
    backgroundColor: '#f0f0f0', //colorNeutralBackground4
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    width: '100%',
    justifyContent: 'spcace-between'
  }),
  title: css({
    gap: '12px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  }),
  controls: css({
    display: 'flex',
    alignItems: 'center'
  }),
  popoverHeader: css({
    margin: '0',
    paddingBottom: '2px',
    fontStyle: 'normal',
    fontWeight: '600'
  }),
  popover: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '24px',
    gap: '10px',
    width: '398px'
  }),
  input: css({
    width: '100%'
  }),
  buttons: css({
    display: 'flex',
    alignSelf: 'end',
    gap: '8px'
  }),
  alerts: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '0 72px'
  })
}

const navIcon: IIconProps = { iconName: 'GlobalNavButton' }

interface ChatWindowProps {
  chatListIsOpen: boolean
  toggleChatList: () => void
}
export const ChatWindow: React.FC<ChatWindowProps> = ({
  chatListIsOpen,
  toggleChatList
}) => {
  // const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <div css={classes.root}>
      <div css={classes.header}>
        <div css={classes.title}>
          {!chatListIsOpen && (
            <IconButton iconProps={navIcon} onClick={toggleChatList} />
          )}
          Chat
        </div>
      </div>
      <ChatRoom />
    </div>
  )
}
