import { IChatMessage } from '../models/ChatMessage'
import { IChatParticipant } from '../models/ChatParticipant'
import {
  IChatSession,
  ICreateChatSessionResponse,
  RouteLocation
} from '../models/ChatSession'
import { IChatUser } from '../models/ChatUser'
import { IAsk } from '../semantic-kernel/model/Ask'
import { IAskResult } from '../semantic-kernel/model/AskResult'
import { BaseService } from './BaseService'

const BASE_COMMAND_PATH = 'rcopilot/'

export class ChatService extends BaseService {
  constructor(serviceUrl: string) {
    super(serviceUrl)
  }

  public createChatAsync = async (
    title: string,
    location: RouteLocation,
    householdId: string,
    accessToken: string
  ): Promise<ICreateChatSessionResponse> => {
    const body = {
      title,
      location,
      householdId
    }

    const result = await this.getResponseAsync<ICreateChatSessionResponse>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats`,
        method: 'POST',
        body
      },
      accessToken
    )
    return result
  }

  public getChatAsync = async (
    chatId: string,
    accessToken: string
  ): Promise<IChatSession> => {
    const result = await this.getResponseAsync<IChatSession>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats/${chatId}`,
        method: 'GET'
      },
      accessToken
    )
    return result
  }

  public getAllChatsAsync = async (
    accessToken: string
  ): Promise<IChatSession[]> => {
    const result = await this.getResponseAsync<IChatSession[]>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats`,
        method: 'GET'
      },
      accessToken
    )
    return result
  }

  public getChatMessagesAsync = async (
    chatId: string,
    skip: number,
    count: number,
    accessToken: string
  ): Promise<IChatMessage[]> => {
    const result = await this.getResponseAsync<IChatMessage[]>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats/${chatId}/messages?skip=${skip}&count=${count}`,
        method: 'GET'
      },
      accessToken
    )

    // Messages are returned with most recent message at index 0 and oldest message at the last index,
    // so we need to reverse the order for render
    return result.reverse()
  }

  public editChatAsync = async (
    chatId: string,
    title: string,
    systemDescription: string,
    memoryBalance: number,
    location: RouteLocation,
    householdId: string,
    suggestedQuestions: string[],
    accessToken: string
  ): Promise<any> => {
    const body: IChatSession = {
      id: chatId,
      title,
      systemDescription,
      memoryBalance,
      location: location, // edit will not modify the route
      householdId,
      suggestedQuestions
    }

    const result = await this.getResponseAsync<IChatSession>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats/${chatId}`,
        method: 'PATCH',
        body
      },
      accessToken
    )

    return result
  }

  public deleteChatAsync = async (
    chatId: string,
    accessToken: string
  ): Promise<object> => {
    const result = await this.getResponseAsync<object>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats/${chatId}`,
        method: 'DELETE'
      },
      accessToken
    )

    return result
  }

  public getBotResponseAsync = async (
    ask: IAsk,
    accessToken: string
  ): Promise<IAskResult> => {
    const chatId = ask.variables?.find((variable) => variable.key === 'chatId')
      ?.value as string
    const result = await this.getResponseAsync<IAskResult>(
      {
        // commandPath: `chats/${chatId}/${processPlan ? 'plan' : 'messages'}`,
        // commandPath: `rcopilot/chats/${chatId}`,
        commandPath: `${BASE_COMMAND_PATH}chatstream/${chatId}`,
        method: 'POST',
        body: ask
      },
      accessToken
    )
    return result
  }

  public getAllChatParticipantsAsync = async (
    chatId: string,
    accessToken: string
  ): Promise<IChatUser[]> => {
    const result = await this.getResponseAsync<IChatParticipant[]>(
      {
        commandPath: `${BASE_COMMAND_PATH}chats/${chatId}/participants`,
        method: 'GET'
      },
      accessToken
    )

    const chatUsers = result.map<IChatUser>(
      (participant: IChatParticipant) => ({
        id: participant.userId,
        online: false,
        fullName: '', // The user's full name is not returned from the server
        emailAddress: '', // The user's email address is not returned from the server
        isTyping: false,
        photo: ''
      })
    )
    return chatUsers
  }
}

export {}
