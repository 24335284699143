import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import {
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize
} from '@fluentui/react/lib/Persona'
import { Text } from '@fluentui/react/lib/Text'
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip'
import { useId } from '@fluentui/react-hooks'
import React, { useState } from 'react'
import CopilotIcon from '../../../../../features/SideNav/assets/CopilotIcon.png'
import { AuthorRoles, IChatMessage } from '../../../libs/models/ChatMessage'
import { timestampToDateString } from '../../utils/TextUtils'
import * as utils from '../../utils/TextUtils'
import { ChatHistoryTextContent } from './ChatHistoryTextContent'

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '75%',
    minWidth: '24em',
    borderRadius: '4px',
    '@media (max-width: 744px)': {
      maxWidth: '100%'
    },
    gap: '4px'
  }),
  debug: css({
    position: 'absolute',
    top: '-4px',
    right: '-4px'
  }),
  alignEnd: css({
    alignSelf: 'flex-end'
  }),
  persona: css({
    paddingTop: '8px'
  }),
  item: css({
    backgroundColor: '#ffffff', //colorNeutralBackground1
    borderRadius: '4px',
    padding: '12px 16px'
  }),
  me: css({
    backgroundColor: '#e8ebf9', //colorMeBackground defined in styles
    width: '100%'
  }),
  time: css({
    color: '#616161', //colorNeutralForeground3
    fontSize: '11px', // customTokens.fontSizeBase200 - may need to change
    fontWeight: 400,
    marginTop: '7.5px'
  }),
  header: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  }),
  canvas: css({
    width: '100%',
    textAlign: 'center'
  }),
  image: css({
    maxWidth: '250px'
  }),
  blur: css({
    filter: 'blur(5px)'
  }),
  controls: css({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
    marginBottom: '8px',
    gap: '16px'
  }),
  citationButton: css({
    marginRight: 'auto'
  }),
  rlhf: css({
    marginLeft: 'auto'
  }),
  text: css({
    marginTop: '5px',
    fontWeight: '550'
  }),
  details: css({
    '&:hover': {
      color: '#84744D' // Change the color on hover
    },
    cursor: 'pointer',
    fontSize: '12px',
    display: 'flex',
    float: 'left'
  }),
  underlined: css({
    textDecoration: 'underline'
  }),
  copy: css({
    height: '20px',
    width: '20px',
    marginTop: '2px'
  })
}

const copyIcon: IIconProps = { iconName: 'Copy' }
const clipboardIcon: IIconProps = { iconName: 'ClipboardList' }

const calloutProps = { gapSpace: 0 }
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' }
}

interface ChatHistoryItemProps {
  message: IChatMessage
  messageIndex: number
}

export const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
  message,
  messageIndex
}) => {
  const tooltipId = useId('tooltip')
  const isUser = message.authorRole === AuthorRoles.User
  const isBot = message.authorRole === AuthorRoles.Bot
  const fullName = message.userName

  const [messagedCopied, setMessageCopied] = useState(false)
  const [intentClicked, setIntentClicked] = useState(false)

  const copyOnClick = async () => {
    await navigator.clipboard.writeText(message.content).then(() => {
      setMessageCopied(true)
    })
  }

  const avatar: IPersonaSharedProps = isBot
    ? { imageUrl: CopilotIcon, size: PersonaSize.size24 }
    : { imageUrl: undefined, size: PersonaSize.size24 }

  const content: JSX.Element = <ChatHistoryTextContent message={message} />

  return (
    <div
      css={isUser ? [classes.root, classes.alignEnd] : classes.root}
      data-testid={`chat-history-item-${messageIndex}`}
      data-username={fullName}
      data-content={utils.formatChatTextContent(message.content)}
    >
      {
        <Persona
          css={classes.persona}
          imageUrl={avatar.imageUrl}
          size={avatar.size}
          presence={!isUser ? PersonaPresence.online : undefined}
        />
      }
      <div css={isUser ? [classes.item, classes.me] : [classes.item]}>
        <div css={classes.header}>
          {/* take out the !isMe in order to display the user's name in the chat */}
          {!isUser && <Text css={classes.text}>{fullName}</Text>}
          <Text css={classes.time}>
            {timestampToDateString(message.timestamp, true)}
          </Text>
          <TooltipHost
            content={messagedCopied ? 'Copied' : 'Copy text'}
            id={tooltipId}
            styles={hostStyles}
            calloutProps={calloutProps}
          >
            <IconButton
              title={messagedCopied ? 'Copied' : 'Copy text'}
              css={classes.copy}
              iconProps={messagedCopied ? clipboardIcon : copyIcon}
              onClick={() => {
                void copyOnClick()
              }}
            />
          </TooltipHost>
        </div>
        {content}
        {isBot && (
          <div>
            {!intentClicked && (
              <Text
                css={classes.details}
                onClick={() => setIntentClicked(true)}
              >
                <b css={classes.underlined}>intent</b>
              </Text>
            )}
            {intentClicked && (
              <Text
                css={classes.details}
                onClick={() => setIntentClicked(false)}
              >
                <b css={classes.underlined}>intent</b>
                :<br /> {message.intent ?? 'Intent Placeholder'}
              </Text>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
