import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IActivity, IDynamicsApiResult } from 'api/dynamics'
import { chunk, orderBy } from 'lodash'
import { stringify } from 'query-string'
import { isNotNullOrUndefined } from 'shared/guards'
import { dynamicsApi } from 'store/api/dynamics'

export interface ITask {
  subject?: string
  description?: string
  'scheduledend@OData.Community.Display.V1.FormattedValue'?: string
  scheduledend?: string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue'?: string
  _regardingobjectid_value?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  _ownerid_value?: string
  rcm_sendemail?: boolean
  prioritycode?: number
  actualdurationminutes?: number
  statuscode?: number
  activityid?: string
  'statecode@OData.Community.Display.V1.FormattedValue'?: string
  statecode?: number
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  'statuscode@OData.Community.Display.V1.FormattedValue'?: string
}
export interface IActivityParty {
  '_partyid_value@OData.Community.Display.V1.FormattedValue'?: string
  _partyid_value?: string
}
export interface IGroupTask extends ITask {
  fax_activity_parties?: IActivityParty[]
}

type DynamicsApiTagType = 'Tasks' | 'rdot360'

const dynamicsApiTags: DynamicsApiTagType[] = ['Tasks', 'rdot360']

const dynamicsApiWithTags = dynamicsApi.enhanceEndpoints({
  addTagTypes: dynamicsApiTags
})

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

export const tasksDynamicsApi = dynamicsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    rdot360_createGroupTask: builder.mutation<
      IGroupTask,
      {
        taskPayload: IGroupTaskPayload
      }
    >({
      query: ({ taskPayload }) => ({
        url: `/faxes`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_createTask: builder.mutation<
      IActivity,
      {
        taskPayload: IAddTaskPayload
        contacts: string[]
        startDate?: string
        endDate?: string
      }
    >({
      query: ({ taskPayload }) => ({
        url: `/tasks`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_editGroupTask: builder.mutation<
      IGroupTask,
      {
        taskPayload: IGroupTaskPayload
        id: string
      }
    >({
      query: ({ taskPayload, id }) => ({
        url: `/faxes(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_editTask: builder.mutation<
      IActivity,
      {
        taskPayload: IAddTaskPayload
        id: string
      }
    >({
      query: ({ taskPayload, id }) => ({
        url: `/tasks(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_reassignGroupTask: builder.mutation<
      undefined,
      { id: string; taskPayload: IReassignGroupTaskPayload }
    >({
      query: ({ id, taskPayload }) => ({
        url: `/faxes(${id})`,
        method: 'PATCH',
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_reassignTask: builder.mutation<
      undefined,
      { id: string; taskPayload: IReassignTaskPayload }
    >({
      query: ({ id, taskPayload }) => ({
        url: `/tasks(${id})`,
        method: 'PATCH',
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    getTasksAndGroupTasksByContacts: builder.query<
      IGroupTask[] | undefined,
      {
        contactIds: string[]
        startDate?: string
        endDate?: string
        order?: 'asc' | 'desc'
        searchText?: string
      }
    >({
      queryFn: async (
        { contactIds, startDate, endDate, order, searchText },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkedIds = chunk(contactIds, 20)
        const requests = chunkedIds.map((ids) => ({
          method: 'GET',
          url: '/activitypointers',
          params: {
            $filter: [
              `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`,
              startDate && `scheduledend ge '${startDate}'`,
              endDate && `scheduledend le '${endDate}'`,
              searchText &&
                `(contains(description, '${searchText}') or contains(subject, '${searchText}'))`
            ]
              .filter(Boolean)
              .join(' and '),
            $orderby: `scheduledend ${order || 'desc'}`,
            $select: 'activitytypecode',
            $expand: `activity_pointer_task($select=${[
              'subject',
              'scheduledend',
              '_regardingobjectid_value',
              '_ownerid_value',
              'rcm_sendemail',
              'prioritycode',
              'actualdurationminutes',
              'statuscode',
              'activityid',
              'statecode',
              'modifiedon',
              'description'
            ].join(',')}),activity_pointer_fax($select=${[
              'subject',
              'scheduledend',
              '_regardingobjectid_value',
              '_ownerid_value',
              'rcm_sendemail',
              'prioritycode',
              'statuscode',
              'activityid',
              'statecode',
              'modifiedon',
              'description'
            ]};$expand=fax_activity_parties($select=_partyid_value))`
          },
          paramsSerializer,
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
          }
        }))
        const responses = await Promise.all(
          requests.map(
            async (x) =>
              baseQuery(x) as Promise<
                QueryReturnValue<IDynamicsApiResult<IActivityTask>>
              >
          )
        )
        const orderedResponses = orderBy(
          responses
            .flatMap((x) => x.data?.value || [])
            ?.map((x) =>
              x?.activitytypecode === 'fax'
                ? x?.activity_pointer_fax?.[0]
                : x?.activity_pointer_task?.[0]
            )
            ?.filter(isNotNullOrUndefined),
          'scheduledend',
          order || 'desc'
        )
        return { data: orderedResponses }
      },
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: 60 * 20
    })
  })
})

export const {
  useRdot360_createGroupTaskMutation,
  useRdot360_createTaskMutation,
  useRdot360_editGroupTaskMutation,
  useRdot360_editTaskMutation,
  useRdot360_reassignGroupTaskMutation,
  useRdot360_reassignTaskMutation,
  useGetTasksAndGroupTasksByContactsQuery,
  useLazyGetTasksAndGroupTasksByContactsQuery
} = tasksDynamicsApi
export interface IAddTaskPayload {
  actualdurationminutes?: number
  description?: string
  subject?: string
  'ownerid_task@odata.bind'?: string
  'regardingobjectid_contact_task@odata.bind'?: string
  'regardingobjectid_account_task@odata.bind'?: string
  prioritycode?: number
  scheduledend?: string
  statecode?: number
  statuscode?: number
  rcm_sendemail?: boolean
}
export interface IGroupTaskPayload {
  scheduledend?: string
  description?: string
  subject?: string
  rcm_sendemail?: boolean
  prioritycode?: number
  statuscode?: number
  statecode?: number
  fax_activity_parties?: IGroupTaskPartyPayload[]
  'regardingobjectid_contact_fax@odata.bind'?: string
  'regardingobjectid_account_fax@odata.bind'?: string
}
export interface IReassignGroupTaskPayload {
  fax_activity_parties?: IGroupTaskPartyPayload[]
}
interface IGroupTaskPartyPayload {
  'partyid_systemuser@odata.bind'?: string
  participationtypemask?: number
}
export interface IReassignTaskPayload {
  'ownerid_task@odata.bind'?: string
}
export interface IActivityTask {
  activitytypecode?: string
  activity_pointer_fax?: IGroupTask[]
  activity_pointer_task?: ITask[]
}
