import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../modules/Investments/InvestmentsDetailsUIState'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { useNavigateTo } from './useTodaysChangeRedirections'

interface BalanceSummaryData {
  label: string
  amount: number
  change?: number
  type?: string
  noStyle?: boolean
  override?: string | false
  onClick?: () => void
}

export const useBalanceSummary = () => {
  const {
    selectedAccountBalanceSummary,
    isFetching,
    error,
    refetch,
    cashInSelectedBrokerageAccounts,
    isMultiMarginInSelectedAccounts,
    isUninitialized
  } = useRdot360BalancesContext()

  const navigate = useNavigate()
  const { setSearchText } = useInvestmentsDetailsUIState()
  const { setInvestmentsViewByKey } = useInvestmentsTableStore()
  const { handleActivityNavigation } = useNavigateTo()

  const balanceSummaryData = useMemo(() => {
    const data = selectedAccountBalanceSummary
    const navigateToInvestment = () => {
      setSearchText('')
      setInvestmentsViewByKey()
      navigate('./investments')
    }

    const mappedData: BalanceSummaryData[] = [
      {
        label: 'Cash',
        amount: data?.cash,
        change: data?.cashChange,
        onClick: () => handleActivityNavigation('Cash'),
        type: 'A'
      },
      {
        label: 'Priced Investments',
        amount: data?.pricedinvestments || 0,
        change: data?.pricedinvestmentschange || 0,
        onClick: navigateToInvestment,
        type: 'A'
      },
      {
        label: 'Annuities and Insurance',
        amount: data?.annuity || 0,
        change: data?.annuitychange || 0,
        type: 'A'
      },
      {
        label: 'Other',
        amount: data?.other,
        change: data?.otherChange,
        type: 'A'
      },
      {
        label: 'Accrued Income',
        amount: data?.holdingAccruIncome || 0,
        change: data?.fixedincomeaccruedinterestchange || 0,
        onClick: navigateToInvestment,
        type: 'A'
      },
      {
        label: 'Loans',
        amount: data?.totalLiabilities,
        change: data?.totalLiabilitiesChange,
        type: 'A'
      },
      {
        label: 'Available to Withdraw',
        amount: data?.availableToWithdraw,
        change: undefined,
        override: isMultiMarginInSelectedAccounts && 'Multi Margin',
        type: 'B'
      },
      {
        label: 'Available to Invest',
        amount: data?.availableToInvest,
        change: undefined,
        override: isMultiMarginInSelectedAccounts && 'Multi Margin',
        type: 'B'
      },
      {
        label: 'Cash in Brokerage Account(s)',
        amount: cashInSelectedBrokerageAccounts,
        change: undefined,
        noStyle: true,
        type: 'B'
      }
    ]

    return mappedData
  }, [
    cashInSelectedBrokerageAccounts,
    handleActivityNavigation,
    isMultiMarginInSelectedAccounts,
    navigate,
    selectedAccountBalanceSummary,
    setInvestmentsViewByKey,
    setSearchText
  ])

  const totalBalanceSummary = useMemo(
    () => ({
      label: 'Total',
      amount: selectedAccountBalanceSummary?.netWorth,
      change: selectedAccountBalanceSummary?.netWorthChange,
      todayschange: selectedAccountBalanceSummary?.todayschange,
      totalNonNfsValueChange:
        selectedAccountBalanceSummary?.totalNonNfsValueChange,
      totalaccountvaluechange:
        selectedAccountBalanceSummary?.totalaccountvaluechange,
      totalotherassetsvaluechange:
        selectedAccountBalanceSummary?.totalotherassetsvaluechange,
      totalexternalaccountvaluechange:
        selectedAccountBalanceSummary?.totalexternalaccountvaluechange,
      onClick: () => navigate('./balances')
    }),
    [selectedAccountBalanceSummary, navigate]
  )

  const filterData = (type: string) =>
    balanceSummaryData.filter((item) => item.type === type)

  return {
    isBalanceSummaryLoading: isFetching,
    isBalanceSummaryError: error,
    balanceSummaryRefetch: refetch,
    totalBalanceSummary,
    balanceSummaryData,
    balanceSummaryCategoryAData: filterData('A'),
    balanceSummaryCategoryBData: filterData('B'),
    isMultiMarginInSelectedAccounts,
    isUninitialized
  }
}
