import { css } from '@emotion/react'
import { format } from 'date-fns'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useEffect } from 'react'
import { FormattedNumber } from 'react-intl'
import { useCopilotMemory } from 'store/api/rcopilot'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { useGetRevenueSummaryData } from '../../../features/Revenue/useRevenueContext'
import { PeriodAnnualized } from './PeriodAnnualized'

const classes = {
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  })
}
const Divider: React.FC = () => {
  return (
    <div
      css={{
        position: 'relative',
        height: '63px',
        border: '1px solid #B4C6D4'
      }}
    />
  )
}
const Divider2: React.FC = () => {
  return (
    <div
      css={{
        position: 'relative',
        width: '90%',
        border: '1px solid #B4C6D4'
      }}
    />
  )
}

interface TopRevenueSummaryProps {
  title?: string
  value?: number
  isFetching: boolean
}
const TopRevenueSummary: React.FC<TopRevenueSummaryProps> = ({
  title,
  value = 0
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div css={{ fontSize: '16px' }}>{title}</div>
      <div css={{ fontSize: '26px' }}>
        {!!value && (
          <FormattedNumber
            notation="compact"
            compactDisplay="short"
            maximumFractionDigits={2}
            style="currency"
            currency="USD"
            currencySign="accounting"
            value={value}
          />
        )}
        {!value && '--'}
      </div>
    </div>
  )
}

export function dateFormat(period?: string) {
  if (!period) {
    return
  }
  const result = format(new Date(period), `MMM ''yy`)
  return result
}

export const Summary: React.FC<{
  isAnnual: boolean
}> = ({ isAnnual }) => {
  const { data, isFetching, isUninitialized } = useGetRevenueSummaryData()
  const { addObjectToMemory } = useCopilotMemory()
  const { selectedHouseholdId } = useRdot360Context()
  useEffect(() => {
    if (isFetching || isUninitialized) {
      return
    }
    addObjectToMemory(
      data,
      'copilot-client-dashboard',
      ['revenue'],
      selectedHouseholdId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addObjectToMemory, data, isFetching, isUninitialized])
  const monthlyData = data?.prior3Months
  const currDate = new Date()

  const annualT12Title = `T12 as of ${
    isAnnual &&
    data?.mostRecentlyClosed?.period &&
    format(new Date(data?.mostRecentlyClosed?.period), `MMM ''yy'`)
  }`
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '80%',
        alignItems: 'center'
      }}
    >
      <div
        css={{
          display: 'flex',
          height: '50%',
          width: '100%',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        {isAnnual ? (
          <>
            <TopRevenueSummary
              title={`${currDate.getFullYear()} YTD`}
              value={data?.currentYear?.ytdRevenue}
              isFetching={isFetching}
            />
            <Divider />
            <TopRevenueSummary
              title={data ? annualT12Title : 'T12'}
              value={data?.mostRecentlyClosed?.t12Revenue}
              isFetching={isFetching}
            />
            <Divider />
            <TopRevenueSummary
              title={`${currDate.getFullYear() - 1} Total`}
              value={data?.priorYear?.annualizedRevenue}
              isFetching={isFetching}
            />
          </>
        ) : (
          <>
            {monthlyData
              ? monthlyData.map((month, i) => {
                  return (
                    <>
                      <TopRevenueSummary
                        title={dateFormat(month?.period)}
                        value={month.periodRevenue}
                        isFetching={isFetching}
                      />
                      {i !== monthlyData.length - 1 && <Divider />}
                    </>
                  )
                })
              : [1, 2, 3].map((index) => {
                  return (
                    <>
                      <TopRevenueSummary
                        title={''}
                        value={undefined}
                        isFetching={isFetching}
                      />
                      {index < 3 && <Divider />}
                    </>
                  )
                })}
          </>
        )}
      </div>
      <Divider2 />
      <div
        css={{
          width: '100%',
          height: '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }}
      >
        <div css={{ paddingLeft: '5%' }}>
          {isAnnual
            ? `Period Annualized vs ${currDate.getFullYear() - 1} Total`
            : 'Period vs Same Period 1 year Prior'}
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%'
          }}
        >
          <PeriodAnnualized data={data} isAnnual={isAnnual} />
        </div>
      </div>
      {isFetching && (
        <div css={classes.progressIndicatorContainer}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </div>
  )
}
